.other-list {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 50px auto;
  height: fit-content;
}
.other-heading {
  margin: 50px auto auto auto;
  font-weight: 700;
  font-size: 40px;
  color: #0d5ba6e5;
}
.other-cards{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-evenly;
    width: 100%;
    margin: 40px auto;
}
.other-card{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.project-name{
    width: 100%;
}
.project-thumb{
    width: 100%;
    height: 380px;
    object-fit: cover;
}
.project-tags{
    height: 50px;
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0px;
}
.tags{
    width: fit-content;
    height: 20px;
    background-color: #E3E3E3;
    padding: 8px;
    color: #000000;
    margin: auto 20px auto 0px;
}

@media (max-width: 768px) {
    .other-list{
        width: 100%;
    }
    .other-heading{
        text-align: center;
    }
    .other-cards{
        display: flex;
        width: 100%;
        overflow-y: hidden;
        justify-content: unset;
        overflow-x: scroll;
    }
    .other-card{
        width: 100%!important;
        margin:auto 10px;
    }
    .project-thumb{
        height: 250px;
        width: 250px;
    }
    .tags{
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
        padding: 2px;
        width: max-content;
    }
    .project-name{
        font-size: 18px;
    }
    
}

/* Amenities */
.am-description{
    color:#1D1D1D;
    font-size: 18px;
    width: 95%;
    margin: auto;
    text-align: justify;
    line-height: 28px;
}

@media (max-width: 768px) {
    .am-description{
        width: 90%;
        line-height: 35px;
    }
    .project-name{
        text-align: center;
    }
}