.form-section{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 50px auto;
    height: fit-content;
}
.section-heading{
    margin: auto;
    font-weight: 700;
    font-size: 40px;
    color: #0D5BA6E5;
}
.section-caption{
    color: #595959;
    font-size: 20px;
    width: 40%;
    margin: 20px auto;
    text-align: center;
    font-weight: 400;
}
.form-container{
    display: flex;
    background-color: #FFFFFF;
}
.form-info{
    display: flex;
    flex-direction: column;
    width: 30%;
    padding-left: 40px;
    height: 500px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 1px;
    background-color: #02225F;
    background-image: url('/src/Assets/waves.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}
.info-line{
    margin-top: 40px;
    width: 80%;
}
.form-detail{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 100;
    width: 260px;
    height: 100px;
    margin-top: 100px;
    justify-content: space-evenly;
}
.form-sheet{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 55%;
    margin: 0px auto;
}
.form-row{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.form-input{
    height: 70px;
    width: 40%;
}
.form-label{
    display: flex;
    align-items: center;
    height: 50%;
    color:#011627;
    font-size: 14px;
    font-weight: 300;
}
.form-value{
    display: flex;
    height: 50%;
    width: 100%;
    border: 0px solid transparent;
    border-bottom: 1px solid #011627;
    outline: none;
}
.form-send{
    margin: auto;
    width: 150px;
    height: 60px;
    outline: none;
    background-color: #0D5BA6E5;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    border: 0px;
    cursor: pointer;
}
.done-form{
    margin: auto;
}
.circle {
  position: relative;
  background: #8cc63e;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 20px auto;
}

.checkmark {
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 35%;
  top: 45%;
  height: 50px;
  width: 25px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}

@media (max-width: 768px) {
    .form-section{
        width: 90%;
    }
    .section-caption{
        width: 100%;
    }
    .form-container{
        flex-direction: column;
        height: 100%;
        padding-bottom: 40px;
    }
    .form-info{
        width: 100%;
        padding: 0px;
        align-items: center;
    }
    .form-sheet{
        width: 100%;
    }
    .form-row{
        flex-direction: column;
    }
    .form-input{
        width: 75%!important;
        margin: 20px 0px 20px 25px;
    }
    .form-send{
        height: 50px;
        width: 100px;
        margin: 30px auto;
    }
}