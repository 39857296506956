.about-section {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 50px auto;
  height: fit-content;
}
.about-heading {
  margin: 50px auto auto auto;
  font-weight: 700;
  font-size: 40px;
  color: #0d5ba6e5;
}
.about-info{
    display: flex;
    border-bottom: 1px solid #3A3B3E;
    margin: 50px auto 0px  auto;
    padding-bottom: 100px;
}
.about-img{
    width: 400px;
    margin: auto;
    box-shadow: -30px -30px #A6A7A9;
}
.about-text{
    width: 45%;
    margin: 10px auto;
    letter-spacing: 1px;
    line-height: 25px;
}
.about-stats{
    display: flex;
    height: 70px;
    width: 60%;
    background-color: #f5f5f5;
    margin-top: -25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
}
.about-stats hr{
    display: none;
}
.about-stat{
    display: flex;
    height: 60px;
    width: 180px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    color: #5F6066;
}
.about-stat div{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #207FDB;
    font-size: 40px;
    width: 60px;
    height: 100%;
}
.about-stat span{
    display: flex;
    justify-content: center;
    margin: auto;
    width: 60%;
}
@media (max-width: 768px) {
    .about-section{
        height: 100%;
    }
    .about-info{
        flex-direction: column;
        margin: 50px auto;
        padding: 0px;
        border: 0px none;
    }
    .about-stats{
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    .about-stats hr{
        width: 100%;
        display: block;
    }
    .about-stat{
        margin: -40px auto 70px auto;
    }
    .about-img{
        width: 90%;
        margin-right: -10px;
    }
    .about-text{
        width: 100%;
        margin: 20px auto;
    }
}