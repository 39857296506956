.hero-header {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
}
.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-heading {
  display: flex;
  width: 40%;
  height: 220px;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #262626b2;
  color: #ffffff;
  padding: 30px;
  letter-spacing: 1px;
}
.hero-heading-ext{
  height: 270px!important;
}
.hero-title {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin: 0px;
}
.hero-subtitle {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin: 10px;
}
.hero-caption {
  color: #f4f4f4 !important;
  font-size: 18px;
  width: 80%;
  text-align: center;
  margin: auto;
}
.hero-btn {
  width: 160px;
  height: 45px;
  font-size: 16px;
  text-transform: uppercase;
  outline: none;
  border: 0px;
  color: #ffffff;
  background-color: #0d5ba6e5;
  margin: auto auto -40px auto;
  cursor: pointer;
}
@media (max-width: 768px) {
  .hero-heading {
    display: flex;
    width: 80%;
    height: fit-content;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transform: unset;
    margin: auto;
    background-color: #262626b2;
    color: #ffffff;
    padding: 20px;
    letter-spacing: 1px;
  }
  .hero-header{
    height: 800px;
  }
  .hero-heading-ext{
  height: fit-content!important;
}
  .hero-title {
    line-height: 60px;
  }
  .hero-caption {
    margin: 10px auto;
    width: 100%;
  }
}
@media (max-height: 768px) {
  .hero-header{
    height: 600px;
  }
}
