.layouts-container{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: fit-content;
    margin: auto;
}
.layouts-heading{
  margin: 50px auto auto auto;
  font-weight: 700;
  font-size: 40px;
  color: #0d5ba6e5;
}
.layouts-visuals{
    width: 100%;
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-evenly;
    margin: 40px auto 20px auto;
}
.layout-card{
    width: 100%;
    height: fit-content;
    margin: 15px auto;
}
@media (max-width: 768px) {
    .layouts-visuals{
        width: 100%;
        grid-template-columns: auto;
    }
    .layout-card{
        height: 100%;
    }
}