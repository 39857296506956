@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
body{
  background-color: #f5f5f5!important;
  margin: 75px 0px 0px 0px!important;
  padding: 0px;
  font-family: 'Raleway', sans-serif!important;
}
@media (-webkit-device-pixel-ratio: 1.5) {
  * {
    zoom: 98%;
  }
}
@media (-webkit-device-pixel-ratio: 1.25) {
  * {
    zoom: 98%;
  }
}
@media (-webkit-device-pixel-ratio: 1.0) {
  * {
    zoom: 98%;
  }
}
@media (-webkit-device-pixel-ratio: 2.125) {
  * {
    zoom: 98%;
  }
}