.choose-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 50px auto;
    height: fit-content;
    background: linear-gradient(252.67deg, #161B32 1.16%, #161B32 12.81%, #162054 60.16%);
}
.c-section-heading{
    margin: 50px auto auto  auto;
    font-weight: 700;
    font-size: 40px;
    color: #FFFFFF;
}
.c-section-caption{
    color: #FFFFFF;
    font-size: 20px;
    width: 40%;
    margin: 20px auto 50px auto;
    text-align: center;
    font-weight: 400;
}
.choose-cards{
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: space-evenly;
    margin: 0px auto 50px auto ;
}
.choose-card{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #ededed;
    width: 325px;
    cursor: auto;
}
.choose-card:nth-child(1){
    background-image: url('../Assets/CC1.png');
    background-repeat: no-repeat;
    background-size: 70px;
    background-position: top right;
}
.choose-card:nth-child(2){
    background-image: url('../Assets/CC2.png');
    background-repeat: no-repeat;
    background-size: 90px;
    background-position: top right;
}
.choose-card:nth-child(3){
    background-image: url('../Assets/CC3.png');
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: top right;
}
.choose-logo{
    height: 42px;
    width: 32px;
    margin: 30px 30px;
}
.choose-card h3{
    margin: 10px 30px;
    letter-spacing: 1px;
}
.choose-reason{
    display: none;
    width: 80%;
    margin: 10px 30px;
    border-top: 1px solid #161B32;
    padding-top: 10px;
    letter-spacing: 1px;
    line-height: unset;
    /* text-align: justify; */
}
.choose-card:hover .choose-reason{
    display: block;
}
@media (max-width: 768px) {
    .choose-cards{
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
    }
    .choose-card{
        width: 85%;
        height: 325px;
        margin: 20px auto;
    }
    .c-section-caption{
        width: 90%;
        line-height: 35px;
    }
}