.highlight-section {
  display: flex;
  background: linear-gradient(
    252.67deg,
    #161b32 1.16%,
    #161b32 12.81%,
    #162054 60.16%
  );
  color: white;
  width: 100%;
  height: fit-content;
  margin: 50px auto;
}
.highlight-info {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 50px auto;
}
.highlight-heading{
    font-size: 40px;
    line-height: 70px;
}
.highlight-caption{
    font-size: 20px;
    line-height: 30px;
}
.highlight-cards {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 60%;
  margin: 50px auto;
}
.highlight-card {
  width: 280px;
  height: 180px;
  background-color: #253356;
  border: 0.5px solid rgba(149, 158, 181, 0.25);
  border-radius: 4px;
  margin: 10px;
}
.card-badge{
    width: 40px;
    height: 40px;
    margin: 20px;
}
.highlight-value{
    margin: 20px;
}

@media (max-width: 768px) {
    .highlight-section{
        flex-direction: column;
    }
    .highlight-info{
        width: 60%;
        margin: auto;
        justify-content: center;
    }
    .highlight-heading , .highlight-caption{
        text-align: center;
    }
    .highlight-cards{
        width: 100%;
        grid-template-columns: 42.5% 42.5%;
        justify-content: space-evenly;
    }
    .highlight-card{
        width: 100%;
        height: 120px;
        margin: 10px auto;
    }
    .highlight-value{
        font-size: 16px;
        margin: 10px 20px;
    }
    .card-badge{
        height: 30px;
        width: 30px;
        margin: 20px auto 10px 20px;
    }
}