.status-section{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}
.status-section span{
    position: absolute;
    right: 10%;
    font-size: 15px;
    text-decoration: none;
    color: black;
}
.status-container{
    display: flex;
    margin: 50px auto;
}
.status-description{
    width: 40%;
    margin: 0px auto 0px 0px;
}
.status{
    font-size: 20px;
    line-height: 32px;
    text-align: justify;
}
.status-visuals{
    width: 50%;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: 42.5% 42.5%;
}
.status-card{
    margin: 10px;
}
.brochure-btn{
    height: 45px;
    width: 200px;
    outline: none;
    border: 0px;
    color: white;
    font-weight: 700;
    background-color: #0D5BA6E5;
    cursor: pointer;
}

@media (max-width: 768px) {
    .status-container{
        flex-direction: column;
    }
    .status-section span{
        display: none;
    }
    .status-visuals{
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        width: 100%;
        justify-content: unset;
    }
    .status-description{
        width: 100%;
        text-align: center;
    }
    .status-section{
        width: 80%;
    }
    .status-container{
        width: 100%;
    }
    .status-card{
        width: 100%;
        margin:10px;
    }
}