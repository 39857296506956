.carousel-container {
  display: flex;
  width: 100%;
  height: 600px;
}
.carousel-back,
.carousel-next {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 30px;
  cursor: pointer;
}
.carousel-next {
  left: unset;
  right: 30px;
}
.carousel-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.indicators {
  display: flex;
  width: fit-content;
  height: 40px;
  position: absolute;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 20%;
}
.indicator-dot {
  width: 15px;
  height: 15px;
  margin: 10px;
  border-radius: 100%;
  background-color: white;
}
.dot-selected {
  background-color: #0c5497;
}

@media (max-width: 768px) {
  .carousel-back,
  .carousel-next {
    top: 40%;
  }
  .indicators{
    bottom: 30%;
  }
}
@media (max-height: 768px) {
  .carousel-back,
  .carousel-next {
    top: 50%;
  }
  .indicators{
    bottom: 20%;
  }
}
