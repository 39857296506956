.subhero-section{
    width: 80%;
    display: flex;
    margin: 50px auto;
}
.subhero-img{
    width: 50%;
    height: 350px;
    margin: auto 0px auto auto;
    box-shadow: -40px -40px #A6A7A9;
    object-fit: cover;
}
@media (max-width: 768px) {
    .subhero-section{
        flex-direction: column;
        height: 100%;

    }
    .subhero-img{
        width: 85%;
        height: fit-content;
        margin: 50px auto;
        box-shadow: -25px -25px #A6A7A9;
    }
}