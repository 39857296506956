header{
    width: 100%;
    height: 75px;
    background-color: white;
    display: flex;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    position: absolute;
    top:0px;
    left: 0px;
    align-items: center;
}
.brand-section{
    height: 60px;
    width: 80px;
    overflow: hidden;
    object-fit: contain;
    margin-left: 100px;
}
.brand-logo{
    width: 100%;
    height: 100%;
}
.nav-links , .ham-nav-links{
    height: 60px;
    width: 50%;
    display: flex;
    align-items: center;
    right: 0px;
    position: absolute;
    justify-content: space-evenly;
}
.nav-link{
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 1px;
    color: #9C9C9C;
}
.link-active{
    color: #0D5BA6E5!important;
    font-family: 17px;
}
.ham-menu{
    display: none;
}
@media (max-width: 768px) {
    .nav-links{
        display: none;
    }
    .brand-section{
        margin-left: 40px;
        height: 50px;
        width: 70px;
    }
    .ham-menu{
        display: block;
        height: 25px;
        width: 30px;
        overflow: hidden;
        cursor: pointer;
        right: 40px;
        position: absolute;
    }
    .ham-ico{
        height: 100%;
        width: 100%;
    }
    .ham-nav-links{
        display: flex;
        flex-direction: column;
        position: absolute;
        top:75px;
        right:0px;
        background-color: white;
        width: 100%;
        height: fit-content;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        justify-content: flex-start;
        align-items: center;
        z-index: 9999;
    }
    .nav-link{
        height: 50px;
        width: 98%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .link-active , .nav-link:hover{
        color: #FFFFFF!important;
        background-color: #0D5BA6E5;
    }
}