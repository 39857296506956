.project-card{
    width: 80%;
    display: flex;
    background-color: #FFFFFF;
    margin: 50px auto;
    text-decoration: none;
    color: inherit;
}
.project-card a{
    margin: auto    ;
}
.project-img{
    width: 250px;
    height: 250px;
}
.project-card-actions{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.project-action{
    height: 45px;
    width: 150px;
    border: 0px;
    outline: none;
    margin: 0px auto 20px auto;
    color: #FFFFFF;
    background-color: #0D5BA6E5;
    font-weight: 700;
    cursor: pointer;
}
.project-info{
    width: 50%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}
.project-title{
    margin: 20px 0px 10px 0px;
}
.project-tags{
    height: 50px;
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0px;
}
.tags{
    width: fit-content;
    height: 20px;
    background-color: #387CBEE5;
    padding: 8px;
    color: #FFFFFF;
    margin: auto 20px auto 0px;
}
.project-details{
    font-size: 18px;
    margin: 10px 0px;
}
.project-des{
    width: 80px;
    height: 80px;
    margin: 0px 0px auto auto;
}
@media (max-width: 768px) {
    .project-card{
        flex-direction: column;
    }
    .project-img{
        width: 100%;
    }
    .project-info{
        width: 100%;
        background-image: url('../Assets/sqbx.png');
        background-size: 80px;
        background-repeat: no-repeat;
        background-position: top right;
    }
    .project-title{
        font-size: 18px;
        width: 90%;
        margin: 20px auto 10px auto;
    }
    .project-tags{
        width: 90%;
        margin: 0px auto;
    }
    .project-details{
        width: 90%;
        margin: 10px auto;
        font-size: 15px;
        text-align: justify;
    }
    .project-des{
        display: none;
    }
    .project-card-actions{
        width: 90%;
        margin: 0px auto;
    }
    .project-action{
        margin: 10px auto ;
    }
}

/* Project Details Page */