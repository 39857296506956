footer {
  height: 400px;
  width: 100%;
  position: relative;
  font-family: Raleway , sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  bottom: 0px!important;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 50px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
linear-gradient(147.17deg, #222945 14.32%, #161B32 27.1%, #182257 78.98%);
}
.footer-main{
    display: flex;
    width: 85%;
    margin: 50px auto 50px auto;
    flex-grow: 1;
}
.foot-brand{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 40px 50px auto auto;
}
.brLogo{
    width: 140px;
    height: auto;
}
.brand-caption{
    margin-top: 30px;
}
.foot-contact{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30%;
    margin: auto;
    justify-content: space-evenly;
}
.foot-detail{
    display: flex;
}
.foot-ico{
    margin-right: 30px;
    width: 25px;
    height:25px;
}
.foot-nav{
    margin: auto;
    width: 30%;
    height: 100%;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #D4D4D4;
}
.twin-sq{
    height: 40px;
    width: 40px;
    vertical-align: middle;
}
.foot-nav-label{
    font-weight: 700;
}
.foot-nav li{
    margin-bottom: 20px;
    list-style-type:none;
    /* text-indent: 60px; */
}
.foot-nav a{
    text-decoration: none;
    color: #D4D4D4;
}
.footer-bar{
    display: flex;
    height: 60px;
    width: 85%;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: #D4D4D4;
    margin: 0px auto;
    border-top: 1px #626161 solid;
    justify-content: space-between;
}
.foot-legal a{
    text-decoration: none;
    color: #D4D4D4;
    margin-right: 15%;
}
.foot-legal{    
    display: flex;
    width: 50%;
}
.sep-hidden{
    display: none;
}
@media (max-width: 768px) {
     footer{
        height: fit-content;
        width: 100%;
     }
    .footer-main{
        flex-direction: column;
        width: 85%;
        margin: 30px auto auto auto;
        flex-grow: 0;
    }
    .foot-nav li{
        text-indent: 0;
    }
    .foot-brand{
        height: fit-content;
        flex-direction: row;
        width: 85%;
        margin: 10px auto!important;
    }
    .brLogo{
        width: 78px;
        height: 62px;
        margin-right: 20px;
    }
    .brand-caption{
        margin: unset;
    }
    .foot-nav{
        align-items: unset;
        justify-content: unset;
        width: 100%;
        height: fit-content;
        margin: 0px auto;
        flex-direction: row;
    }
    .sep-50 {
        display: block;
        width: 50%;
        font-size: 14.5px;
    }
    .sep-50 li{
        text-indent: -10px;
    }
    .foot-contact{
        width: 85%;
        height: fit-content;
        margin: 10px auto!important;
        justify-content: unset;
    }
    .foot-detail{
        margin: 10px 0px;
    }
    .foot-legal{
        display: none;
    }
}
@media (max-width: 400px) {
    .sep-50{
        font-size: 14px;
    }
}